import React, {useState} from 'react';
import './App.css';
import { Button } from '@mui/material';
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material';
import { Box } from '@mui/material';
import { Check, NoEncryption } from '@mui/icons-material';
import { ArrowBack } from '@mui/icons-material';
import { Home } from '@mui/icons-material';
import WhatsApp from '@mui/icons-material/WhatsApp';
import Menu from './Menu';
import WhatsappIcon from '@mui/icons-material/WhatsApp';
import { Grid } from '@mui/material';

const theme = createTheme({
    palette: {
        primary: {
            main: '#000'
        },
        secondary: {
            main: '#5ebc5e'
        }
    }
  })

function Contact({datum, tijd, soort, duur, extra, prijs, prijs2}){
    const [printReservering,setPrintReservering] = useState(false);
    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)
    const [print,setPrint] = useState(false);
    const [print2,setPrint2] = useState(false);
    const [printMenu,setPrintMenu] = useState(false);
    const [show, setShow] = useState(true);
    const [value, setValue] = React.useState('1');
 
    const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
    function getName(val){
      setName(val.target.value)
      setPrint(false)
    }
    function getEmail(val){
      setEmail(val.target.value)
      setPrint(false)
    }
    return(
        <ThemeProvider theme={theme}>
        <div>
            {
                printMenu ? <Menu />  : null
            }
            {show ? 
    <div>
        <div className="formulier"><div style={{marginTop:"50px",}}>
        <center><img style={{marginTop:"-100px",}} width="230px" src="https://www.elmarstechniek.nl/img/logo.png"/></center><br /><Box sx={{ width: '95%', typography: 'body1' }}><center>
        
        <form action="contact.php" method="POST">
        <input style={{ marginBottom: 10 , border: "1px solid black",}} type="text" name="naam" id="naam" placeholder="Naam" required/><br />
        <input style={{ marginBottom: 10, border: "1px solid black", }} type="email" name="email" id="email" placeholder="E-mailadres" required/><br />
        <input style={{ marginBottom: 10, border: "1px solid black", }} type="text" name="telefoonnummer" id="telefoonnummer" placeholder="Telefoonnummer (optioneel)" /><br />
        <textarea style={{ marginBottom: 10 , border: "1px solid black",}} type="textarea" name="bericht" id="bericht" placeholder="Bericht" required/><br />

        <Button startIcon={<ArrowBack />} style={{ margin: 2, marginBottom: -15, height: 30, fontSize: 10, color: "white",}} variant="contained" color="primary" type="submit" onClick={() => setPrintMenu(true) & setShow(false)}>Ga Terug</Button>
        <Button startIcon={<Check />} style={{ margin: 2, marginBottom: -15, height: 30, fontSize: 10, color: "white",}} variant="contained" color="secondary" type="submit">Verzenden</Button>
        <br /><br /><br />

        </form>
        
            </center></Box>
          </div></div></div>
                : null}
</div></ThemeProvider>
    )
}

export default Contact;