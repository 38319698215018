import React, {useState} from 'react';
import './App.css';
import Menu from './Menu.js';
import Contact from './Contact';



function App() {
  return (
    <div>
    <div className="app">
      <center>
      <Menu />
      </center>
    </div>
    </div>
  );
}

export default App;
