import React, {useState} from 'react';
import Contact from './Contact';
import './App.css';
import { Button } from '@mui/material';
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material';
import { Box } from '@mui/material';
import { ForkRight } from '@mui/icons-material';

const theme = createTheme({
    palette: {
        primary: {
            main: '#000'
        },
        secondary: {
            main: '#5ebc5e'
        }
    }
  })

function Menu({datum, tijd, soort, duur, prijs}){
    const [extra, setExtra] = useState("-")
    const [prijs2, setPrijs2] = useState("-")
    const [printGallerij,setPrintGallerij] = useState(false);
    const [printMassages,setPrintMassages] = useState(false);
    const [printTarieven,setPrintTarieven] = useState(false);
    const [printReservering,setPrintReservering] = useState(false);
    const [printContact,setPrintContact] = useState(false);
    const [show, setShow] = useState(true);
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
    function getExtra(val){
        setExtra(val.target.value)
        setPrintTarieven(false)
      }
    return(
        <ThemeProvider theme={theme}>
        <div>
            {
                printContact ? <Contact />  : null
            }
            {show ? 
    <div>
        <div className="formulier"><div style={{marginTop:"85px",}}>
        <center><img style={{marginTop:"-100px",}} width="230px" src="https://www.elmarstechniek.nl/img/logo.png"/></center><br /><Box sx={{ width: '95%', typography: 'body1' }}><center>
        
        <table style={{marginLeft:"36px",}}>
        <a href="tel:0612345678" style={{textDecoration:"none",}}>
            <tr>
                <td><img style={{marginTop:"0px",}} width="20px" src="https://www.elmarstechniek.nl/img/telefoon.png"/></td>
                <td><div style={{marginTop:"-8px",color:"black",}}>06-12345678</div></td>
            </tr>
        </a>
        <a href="mailto:info@elmarstechniek.nl" style={{textDecoration:"none",}}>
            <tr>
                <td><img style={{marginTop:"0px",}} width="23px" src="https://www.elmarstechniek.nl/img/mail.png"/></td>
                <td><div style={{marginTop:"-8px",color:"black",}}>info@elmarstechniek.nl</div></td>
            </tr>
        </a>
        </table>
        
            </center></Box>
        <Box sx={{ width: '100%', typography: 'body1', marginTop: 1, }}><center>
        <Button style={{ border: "1px white solid", color: "white", margin: 2, marginBottom: 10, marginLeft: 20, marginRight: 20, height: 40, width: 200, fontSize: 8, }} variant="contained" color="primary" onClick={() => setPrintContact(true) & setShow(false)}><h2>Stuur mij een bericht</h2></Button></center></Box>

          </div></div></div>
                : null}
</div></ThemeProvider>
    )
}

export default Menu;